.plans-toolbar {
  display: flex;
  justify-content: center;
  padding: 20px 20px 0;

  .p-selectbutton {
    .p-button {
      width: 120px;
    }
  }
}

.plans-submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
  min-height: 400px;
  gap: 20px;

}

.plans {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  padding: 30px;
}

.p-skeleton {
  flex: 1;
}

.plans-modal {
  width: 90%;

  &.p-dialog.modal {
    .p-dialog-header {
      background-color: #f6f6f6;
    }
    .p-dialog-content {
      padding: 0;
      background-color: #f6f6f6;
    }
  }
}


.plan-card {
  flex: 1;
  min-width: 300px;

  .p-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .p-card-title {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    z-index: 1;

  }

  .p-card-content {
    flex: 1;
    padding: 10px 20px 20px;
  }

  .p-card-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 20px;
  }
}

.plan-description {
  color: var(--text-color);
  font-size: 14px;
}

.plan-price {
  padding: 10px 0;
  margin: 20px 0;

  &-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.plan-features {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .icon {
      margin-right: 0.5rem;
      color: $primary-500;
    }
  }
}