$primaryColor: $primary-500;
$primaryDarkColor: $primary-600;
$primaryDarkerColor: $primary-700;

$colors: (
    "blue": #2196F3,
    "green": #4caf50,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #F57C00,
    "bluegray": #607D8B,
    "purple": #9C27B0,
    "red": #FF4032,
    "primary": $primaryColor
);

// Mandatory Designer Variables
@import 'general';
@import 'form';
@import 'button';
@import 'panel';
@import 'data';
@import 'overlay';
@import 'message';
@import 'menu';
@import 'media';
@import 'misc';

:root {
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color:#2196F3;
    --primary-color-text:#ffffff;
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --surface-hover:#e9ecef;
    --focus-ring: #{$focusShadow};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    color-scheme: light;
}
