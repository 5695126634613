.icon {
  vertical-align: top;
  
  // define material symbol default config
  &.material-symbols-outlined {
    transition: color $transitionDuration ease-in-out;
    font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;

    // define preset size options
    &.is {
      &-small {
        font-size: 1rem;
      }

      &-medium {
        font-size: 1.5rem;
      }

      &-large {
        font-size: 2rem;
      }
    }
  }

  &.is-submitting {
    animation: rotate 1.5s linear infinite;
  }
}

@keyframes rotate{
  to { transform: rotate(360deg); }
}