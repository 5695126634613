$overlayContentEllipsisWidth: 160px;
$menuUIColour: var(--text-color);

.entity-switcher {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  background-color: transparent; 
  //border: 1px solid var(--border-color);
  padding: 0.5rem;
  box-shadow: none;
  cursor: pointer;
  border-radius: $radius-small;

  &:hover {
    background-color: var(--surface-100);
    box-shadow: var(--shadow-1);

    .material-symbols-outlined {
      color: var(--primary);
    }
  }
}

// entity OverlayPanel
.entity-popover {
  width: 280px;
  border: 1px solid var(--border-color);
  max-height: 90vh;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .p-submenu-header {
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $menuUIColour;
    letter-spacing: $letter-spacing-xlarge;
    padding: 0 1rem;

    &:not(:first-child) {
      border-top: 1px solid var(--border-color);
      padding-top: 0.75rem;
    }
  }

  .entity-view-action .p-button {
    color: $menuUIColour;
    font-weight: 500;
    transition: color 250ms ease-in-out;
  }

  .p-overlaypanel-content {
    padding: 0;
    overflow: hidden;

    .p-menu {
      width: unset;
      border: none;
      padding: 0;
      border-radius: 0;
      overflow-y: auto;

      > .p-menu-list {
        > li.p-menuitem:not(:last-of-type) {
          border-bottom: 1px solid var(--border-color-light);
        }
      }
    }

    .entity-view-action {
      > button {
        flex: 1;
        justify-content: center;
      }

      &:hover {
        background: transparent;
      }
    }

    .entity-popover_footer {
      padding: 0.5rem;
      margin: 0;
      background-color: var(--opaque-500);
    }
  }
}

#entity_switcher .p-menuitem:hover {
  .material-symbols-outlined {
    opacity: 1;
  }
}

// remove padding-left whilst menuitem-row is a parent
.p-menuitem-row .p-submenu-header {
  padding-left: 0;
}