.p-menubar {
	padding: $horizontalMenuPadding;
	background: $horizontalMenuBg;
	color: $horizontalMenuTextColor;
	border: $horizontalMenuBorder;
	border-radius: $borderRadius;

	.p-menubar-root-list {
		outline: 0 none;

		> .p-menuitem {
			@include horizontal-rootmenuitem-link();
		}
	}

	.p-menuitem {
		@include menuitem-link();
	}

	.p-submenu-list {
		padding: $verticalMenuPadding;
		background: $overlayMenuBg;
		border: $overlayMenuBorder;
		box-shadow: $overlayMenuShadow;
		width: $menuWidth;

		.p-menuitem-separator {
			border-top: $divider;
			margin: $menuSeparatorMargin;
		}

		.p-submenu-icon {
			font-size: $menuitemSubmenuIconFontSize;
		}
	}

	&.p-menubar-mobile {
		.p-menubar-button {
			width: $actionIconWidth;
			height: $actionIconHeight;
			color: $horizontalMenuRootMenuitemIconColor;
			border-radius: $actionIconBorderRadius;
			transition: $actionIconTransition;

			&:hover {
				color: $horizontalMenuRootMenuitemIconHoverColor;
				background: $horizontalMenuRootMenuitemHoverBg;
			}

			&:focus {
				@include focused();
			}
		}

		.p-menubar-root-list {
			padding: $verticalMenuPadding;
			background: $overlayMenuBg;
			border: $overlayMenuBorder;
			box-shadow: $overlayMenuShadow;

			.p-menuitem-separator {
				border-top: $divider;
				margin: $menuSeparatorMargin;
			}

			.p-submenu-icon {
				font-size: $menuitemSubmenuIconFontSize;
			}

			.p-menuitem {
				.p-menuitem-content {
					.p-menuitem-link {
						.p-submenu-icon {
							margin-left: auto;
							transition: transform $transitionDuration;
						}
					}
				}

				&.p-menuitem-active {
					> .p-menuitem-content {
						> .p-menuitem-link {
							> .p-submenu-icon {
								transform: rotate(-180deg);
							}
						}
					}
				}
			}

			.p-submenu-list {
				.p-submenu-icon {
					transition: transform $transitionDuration;
					transform: rotate(90deg);
				}

				.p-menuitem-active {
					> .p-menuitem-content {
						> .p-menuitem-link {
							> .p-submenu-icon {
								transform: rotate(-90deg);
							}
						}
					}
				}
			}

			@include nested-submenu-indents(nth($menuitemPadding, 1), 2, 10);
		}
	}
}

/*
@media screen and (max-width: 960px) {
	.p-menubar {
		position: relative;

		.p-menubar-button {
			display: flex;
			width: $actionIconWidth;
			height: $actionIconHeight;
			color: $horizontalMenuRootMenuitemIconColor;
			border-radius: $actionIconBorderRadius;
			transition: $actionIconTransition;

			&:hover {
				color: $horizontalMenuRootMenuitemIconHoverColor;
				background: $horizontalMenuRootMenuitemHoverBg;
			}

			&:focus {
				@include focused();
			}
		}

		.p-menubar-root-list {
			position: absolute;
			display: none;
			padding: $verticalMenuPadding;
			background: $overlayMenuBg;
			border: $overlayMenuBorder;
			box-shadow: $overlayMenuShadow;
			width: 100%;

			.p-menu-separator {
				border-top: $divider;
				margin: $menuSeparatorMargin;
			}

			.p-submenu-icon {
				font-size: $menuitemSubmenuIconFontSize;

				&.p-icon {
					width: $menuitemSubmenuIconFontSize;
					height: $menuitemSubmenuIconFontSize;
				}
			}

			> .p-menuitem {
				width: 100%;
				position: static;

				> .p-menuitem-link {
					@include menuitem-link();

					> .p-submenu-icon {
						margin-left: auto;
						transition: transform $transitionDuration;
					}
				}

				&.p-menuitem-active {
					> .p-menuitem-link {
						> .p-submenu-icon {
							transform: rotate(-180deg);
						}
					}
				}
			}

			.p-submenu-list {
				width: 100%;
				position: static;
				box-shadow: none;
				border: 0 none;

				.p-submenu-icon {
					transition: transform $transitionDuration;
					transform: rotate(90deg);
				}

				.p-menuitem-active {
					> .p-menuitem-link {
						> .p-submenu-icon {
							transform: rotate(-90deg);
						}
					}
				}
			}

			.p-menuitem {
				width: 100%;
				position: static;
			}

			@include nested-submenu-indents(nth($menuitemPadding, 1), 2, 10);
		}

		&.p-menubar-mobile-active .p-menubar-root-list {
			display: flex;
			flex-direction: column;
			top: 100%;
			left: 0;
			z-index: 1;
		}
	}
}
*/