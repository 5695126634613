.event-details {
    border: 1px solid #E3E3E4;
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 1rem;
}

.p-tabmenu + .p-datatable,
.p-tabmenu + .p-datatable .p-toolbar,
.p-tabmenu + .event-details {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.event-date-box {
  text-align:  center;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: var(--surface-200);

  p {
    margin: 0;
  }

  p:first-child {
    font-size: 24px;
  }
  p:last-child {
    font-size: 14px;
  }
}


.event-scores {
  width: 150px;
}

.event-score {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &-label {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;

    .p-avatar {
      margin-right: 10px;
    }
  }
}