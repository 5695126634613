.p-tooltip .p-button .icon {
  pointer-events: none !important;
}

.p-tooltip .p-button, 
.p-tooltip .p-button:hover {
  background-color: transparent;
  border: none;
  color: #ff6700 !important;
  opacity: 1;
}