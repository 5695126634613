.licences {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
}

.licence-card {
  flex: 1;
  width: 50%;
  min-width: 200px;
}
