.p-datatable { 
  .p-sortable-column {
    
    .p-column-header-content {
      position: relative;
      padding-right: 1.5rem;
    }

    &.p-align-center .p-column-header-content {
      padding-left: 1.5rem;
    }
  
    .p-sortable-column-icon {
      position: absolute;
      right: 0;
      top: 50%; 
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:not(.p-highlight):not(.p-sortable-disabled):hover {
      .p-sortable-column-icon {
          opacity: 1;
      }
    }
    
    &.p-highlight {
      .p-sortable-column-icon {
          opacity: 1;
      }
    }
  }

  .p-frozen-column {
    background-color: $tableBodyRowBg;
  }

  &.p-datatable-striped .p-datatable-tbody > tr.p-row-odd .p-frozen-column {
    background-color: $tableBodyRowEvenBg;
  }
  
}