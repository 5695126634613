.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;

  .sidebar-header {
    flex: none;
    position: relative;
    border-bottom: 1px solid var(--border-color-light);
    overflow: visible;
    padding: 0.75rem;

    img {
      display: inline-block;
      max-height: 40px;
    }
  }

  .sidebar-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 0.75rem 0.75rem;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .p-menu,
    .p-tieredmenu {
      list-style: none;
      width: unset;
      margin: 0.75rem 0;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;


      .p-menuitem {
        position: relative;
        color: var(--text-color-strong);

        .p-menuitem-content {
          border-radius: $radius;
        }

        .p-menuitem-link {
          padding: 0.75rem;
        }

        .p-menuitem-text {
          white-space: nowrap;
        }

        .p-menuitem-icon {
          margin-right: 1rem;
        }

        .p-menuitem-link.p-menuitem-active {
          background-color: var(--primary-opaque);

          .p-menuitem-text,
          .p-menuitem-icon {
            color: var(--primary);
          }
        }

      }
    }
  }

  .sidebar-subheader {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0;

    .list-item {
      .list-item-container {
        gap: 0.5rem;
      }

      .list-item-start {
        padding: 0 0.25rem;
      }
    }
  }

  .sidebar-footer {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--border-color-light);
    padding: 0.75rem;
  }

  .p-button-upgrade {
    justify-content: center;
    flex: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .layout-sidebar-collapsed & {
      .p-button-icon {
        margin: 0;
      }
    }

    .p-button-label {
      flex: none;
    }
  }

  .entity-switcher .list-item-container {
    min-height: 40px;
  }
}

.app-logo {
  display: block;
  white-space: nowrap;
  padding: 0.5rem;

  img {
    height: 32px;
    width: auto;
    max-width: none;
    vertical-align: top;
  }

  .app-logo-wordmark {
    transition: opacity $duration-normal;

    .layout-sidebar-collapsed .layout-sidebar & {
      opacity: 0;
    }
    

    .is-theme-darkest &,
    .is-theme-dark & {
      filter: invert(1);
    }
  }
}

.sidebar-toggle {
  width: 100% !important;
  padding: 0.75rem;
  transition: opacity $duration-normal;
  justify-content: flex-end;
}


// PUSH transition: enter from right (100%), existing panel moves off left.
.sidebar {
  @include react-transition-phase(
    "panel-stack",
    "enter",
    (transform: translateX(100%) translate(0%), opacity: 0 1),
    $easing: ease,
    $duration: 400ms
  );
  @include react-transition-phase(
    "panel-stack",
    "exit",
    (transform: translateX(-50%) translate(0%), opacity: 0 1),
    $easing: ease,
    $duration: 400ms
  );

  &-pop {
    @include react-transition-phase(
      "panel-stack",
      "enter",
      (transform: translateX(-50%) translate(0%), opacity: 0 1),
      $easing: ease,
      $duration: 400ms,
    );
    @include react-transition-phase(
      "panel-stack",
      "exit",
      (transform: translateX(100%) translate(0%), opacity: 0 1),
      $easing: ease,
      $duration: 400ms,
    );
  }
}