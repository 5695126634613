.list {
  padding: 0;
  margin: 0;

  a.list-item,
  button.list-item {
    .list-item-container:not(.is-disabled):hover {
      cursor: pointer;
      background-color: var(--opaque-50);
    }
  }


}

.list-header {
  color: $textSecondaryColor;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-large;
  margin-bottom: 0.5rem;
  font-size: 0.67em;
  font-weight: bold;

  box-sizing: border-box;
  line-height: 3rem;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.list-divider {
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
}

.list-item {
  // reset styles
  background: none;
  border: none;
  cursor: inherit;
  padding: 0px;
  margin: 0px;
  text-align: unset;
  text-decoration: none;
  outline: none;
  border-radius: inherit;
  max-width: inherit;
  min-width: inherit;

  display: flex;
  flex: 1 1 0%;
  width: 100%;

  .list-item-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    height: 100%;
    min-height: 48px;
    gap: 1rem;
    padding: 12px 16px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: $weight-normal;
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-disabled {
      opacity: 0.5;
    }

    &.is-multiline {
      min-height: 72px;
    }

    &.has-no-padding {
      padding: 0;
    }

    &.is-compact {
      .list-item-title {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .list-item-caption {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    &:not(.is-multiline) {
      .list-item-title,
      .list-item-overline,
      .list-item-caption {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .list-item-text {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    overflow: hidden;
  }

  .list-item-title {
    font-weight: 500;
    line-height: 1.5;
    color: var(--text-color-strong);
  }

  .list-item-overline {
    color: var(--text-color-light);
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1rem;
  }

  .list-item-caption {
    color: var(--text-color);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .list-item-support {
    color: var(--text-color-light);
    font-size: 0.6875rem; 
    font-weight: 500;
    line-height: 1rem;
  }

  .list-item-start, 
  .list-item-end {
    fill: currentColor;
  }
}
